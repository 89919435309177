* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100vh;
}

:root {
  --primary-color: #1b2540;
  --gradient: linear-gradient(126.49deg, #96c94a 7.8%, #1278a7 80.15%);
  --text-color: white;
  --black-color: black;
  --smoke-color: #f5f5f5;
}

.gre-button {
  width: 100%;
  color: white !important;
  background: var(--gradient) !important;
}
.gred-button {
  width: 18%;
  color: white !important;
  background: var(--gradient) !important;
  margin-bottom: 10px !important;
}

/* main lauyout */
.container {
  height: 100vh;
}

/* sidebar */

.side-head {
  text-align: center;
  margin: 10px 0;
  font-size: 20px;
}
/* main header */
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-para {
  color: var(--text-color);
}
/* bread Crumb  */
.bread {
  margin: 20px 50px;
}
/* table */
.table {
  width: 90%;
  margin: 50px auto;
}
.editIcon {
  color: green !important;
  margin-left: 20px !important;
}
.delIcon {
  color: red !important;
  margin-left: 20px !important;
}
.form-user {
  width: 90%;
  margin: 50px auto;
  padding: 10px 50px;
  background-color: white;
}

.form-content {
  height: 100px;
}
.form-head {
  text-align: center;
  margin: 5px 0;
  font-weight: bold;
}
.modal-input {
  margin: 10px 0;
}

.header {
  background: var(--primary-color) !important;
}
.sider {
  background: var(--primary-color) !important;
}
.sider-menu {
  background: var(--primary-color) !important;
  color: var(--text-color) !important ;
}

.ant-menu-item:hover {
  color: var(--text-color) !important ;
}
.ant-menu-submenu-title:hover {
  color: var(--text-color) !important ;
}
/* card */
.card {
  margin: 0 auto;
  margin-top: 10px;
}
.card {
  width: 300px !important;
  background: var(--smoke-color) !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  background: var(--gradient) !important;
  color: var(--text-color) !important;
  height: 100px;
}
.card_main {
  font-size: 20px;
  color: black;
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: end;
}
.card-gap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-head {
  flex: 1;
  font-size: 30px;
  font-weight: bold;
}
.view {
  /* width: 500px;
height: 500px;
margin: 20px auto;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding: 20px;
}
.view_hr {
  width: 100%;
}
.view_para {
  margin-top: 20px;
  word-wrap: break-word;
}

.search-bar {
  display: flex;
  justify-content: flex-end;
  padding-right: 65px;
}

.action-icon {
  margin-left: 20px;
  font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .gred-button {
    width: 30%;
    color: white !important;
    background: var(--gradient) !important;
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .form-user {
    margin: 0 auto;
    padding: 20px;
  }
  .gred-button {
    width: 50%;
  }
  .card {
    width: 90% !important;
  }
  .view {
    width: 90%;
    height: 500px;
  }
}
@media screen and (max-width: 390px) {
  .header-para {
    display: none;
  }
}
