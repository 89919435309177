.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}
.login-main{
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    height: 90%;
}
.login-upper{
    height: 50%;
    display: flex;
   align-items: center;
   justify-content: center;
    background-color: var(--primary-color);
}
.login-img{
 width: 70%; 
}
.login-lower{
    height: 50%;
   
}
.login-head{
    text-align: center;
    font-size: 25px;
    padding-top: 10px;
}
.login-para{
    text-align: center; 
    font-size: 10px;
    padding-bottom: 10px;
}
.login-form {
    max-width: 300px;
    margin: 0 auto;
  }
  .login-frm{
      padding:0 10px;
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
    color: white !important;
    background: var(--gradient) !important;
  }

@media only screen and (max-width: 600px) {
    .login-main{
        width: 90%;
    }
    .login-head{
        font-size: 22px;
    }
    .login-para{
        text-align: center; 
        font-size: 13px;
    }
    .pass{
        font-size: 13px;
        margin-top: 15px;
    }
    .login-img{
        width: 50%;
     }
    
  }