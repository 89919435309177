.forget{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}
.forget-main{
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    padding: 20px;
}
.forget-head{
    font-size: 25px;
    padding-top: 10px;
}
.forget-head{
    font-size: 25px;
    padding-top: 10px;
}
.forget-para{
    font-size: 10px;
    padding-bottom: 10px;
}
.varifi-email{
    font-weight: bold;
}
@media only screen and (max-width: 600px) {
    .forget-main{
        width: 90%;   
    }
     .forget-head{
        font-size: 35px;
    } 
    .forget-para{ 
        font-size: 20px;
    }
    
  }